<template>
  <common-modal @close="$emit('close')">
    <h1 class="text-2xl font-semibold">Manage Ambassador Order</h1>
    <p class="mt-5 text-sm text-grey-500">
      Select the order in which ambassadors appear on your landing page and
      widget. A profile must be set to “visible” for it to be displayed on this
      screen.
    </p>
    <draggable
      v-if="editedIndexedAmbassadors.length"
      v-model="editedIndexedAmbassadors"
      @end="moveAmbassador"
      :force-fallback="true"
      :scroll-sensitivity="100"
      ghost-class="ghost"
      class="mt-8 h-80 overflow-y-auto">
      <transition-group type="transition" name="flip-list">
        <div
          v-for="ambassador in editedIndexedAmbassadors"
          :key="ambassador._id"
          class="h-12 my-2">
          <span
            v-if="ambassador.isActive"
            class="flex items-center text-blue font-medium">
            <div class="w-8">{{ ambassador.index }}.</div>
            <div
              class="h-12 w-full px-6 bg-blue-100 flex justify-between items-center rounded-lg cursor-pointer">
              {{ ambassador.first + " " + ambassador.last }}
              <!-- <div v-if="ambassadorStatus[ambassador._id]">New</div> -->
            </div>
          </span>
        </div>
      </transition-group>
    </draggable>
    <div v-else class="mt-8">
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
      <div class="flex items-center text-blue font-medium my-2">
        <div class="w-12 h-12 bg-blue-100 rounded-lg animate-pulse" />
        <div class="h-12 w-full ml-6 bg-blue-100 rounded-lg animate-pulse" />
      </div>
    </div>
    <div class="w-full mt-6 flex justify-between items-center">
      <div class="flex items-center">
        <!-- <input type="checkbox" />
                    <p class="text-grey text-sm ml-3">Move new profiles to the top</p> -->
      </div>
      <common-button
        @click="submitAmbassadorIndex"
        width="w-44"
        :disabled="!editedIndexedAmbassadors.length || saving"
        :loading="saving"
        >Save</common-button
      >
    </div>
  </common-modal>
</template>
<script>
  import { mapGetters } from "vuex";
  import CommonModal from "@/components/common/tailwind/Modal.vue";
  import draggable from "vuedraggable";
  import ambassadorsService from "../ambassadors.service";
  import { GET_BRANDING } from "@/store/getters.type";

  export default {
    props: ["ambassadors"],
    components: {
      CommonModal,
      draggable,
    },
    data() {
      return {
        editedIndexedAmbassadors: [],
        indexedAmbassadors: [],
        ambassadorStatus: {},
        saving: false,
      };
    },
    computed: {
      ...mapGetters({ branding: GET_BRANDING }),
    },
    methods: {
      updateMessage(updatedMessage) {
        this.message = updatedMessage;
      },
      moveAmbassador() {
        this.editedIndexedAmbassadors = this.editedIndexedAmbassadors.map(
          (ambassador, index) => {
            ambassador.index = index + 1;
            return ambassador;
          }
        );
      },
      submitAmbassadorIndex: async function () {
        const ambassadorsWithIndexes = this.editedIndexedAmbassadors;
        const ambassadorIDs = ambassadorsWithIndexes.map(
          (ambassador) => ambassador._id
        );

        this.saving = true;

        const response = await ambassadorsService.updateIndexedAmbassadors(
          ambassadorIDs
        );
        if (response) {
          this.$toasted.show(`Updated Ambassador order.`, this.$toastedSuccess);
        } else {
          this.$toasted.show(
            `Failed to update Ambassador order.`,
            this.$toastedFailure
          );
        }

        this.saving = false;
      },
    },
    async created() {
      const { indexedAmbassadors, filteredIndexedAmbassadors } =
        await ambassadorsService.getIndexedAmbassadors();
      this.indexedAmbassadors = indexedAmbassadors;
      this.editedIndexedAmbassadors = filteredIndexedAmbassadors;
    },
  };
</script>
